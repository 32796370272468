.foot {
  background-color: #3AB2D0;
  
  color: white;
}

a{
  text-decoration: none;
  color: white;
}

.fa{
  font-size: 25px
}

p{
  font-size: larger;
}
.span-tag {
  font-size: larger;
}

@media screen and (max-width: 999px){
  .span-tag{
   font-size: large;
  }
}