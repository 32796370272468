.mobile-num-verification {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mobile-num-verification .main-div {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}

.mobile-num-verification .main-div input,
.mobile-num-verification .main-div button {
  padding: 5px 10px;
  margin-left: 20px;
  border: 1px solid rgba(0, 0, 0, 0.159);
}

#phone {
  margin: 0;
}

.mobile-num-verification .main-div button {
  outline: none;
  padding: 10px;
  width: 100px;
  cursor: pointer;
}

.otpBox {
  display: flex;
}

.otpBox input {
  width: 80px;
}

.status-p {
  font-size: 14px;
}

@media (max-width: 560px) {
  .mobile-num-verification .main-div {
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 0px;
    margin-top: 20px;
  }

  .mobile-num-verification .main-div input,
  .mobile-num-verification .main-div button {
    padding: 5px 10px;
    margin: 10px 0;
    margin-left: 0px;
  }

  #phone {
    width: 100%;
  }
  .otpBox {
    display: flex;
    justify-content: space-between;
  }

  .otpBox input {
    width: 120px;
  }
}
