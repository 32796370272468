.navs {
  background-color: #3AB2D0;
  text-decoration: none;
  color: white;
  text-align:right;
}

a{
    color: white;
}

.ham__btn{
margin-left: auto;
margin-bottom: auto;
}

@media screen and (max-width:990px) {
  .cont {
  display: none;
  width: 90%;
  }
  .nav-item{
    text-align: center;
  }
 
}

@media screen and (max-width:590px) {
  .cont {
    display: none;
    width: 70%;
    }
}