.container {
  .msg {
    text-align: center;
    width: 100%;
    margin-top: 30px;
    font-size: 18px;
    font-weight: 300;
    position: absolute;
    background-color: red;
  }

  .deal-card {
    margin: 20px;
  border-radius: 20px;

  } 

  .deal-card:hover {
 box-shadow: 3px 5px 15px 5px white;
    
    
  }
}
