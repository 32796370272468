.blog-content {
  width: 100%;
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;

  .intro {
    h1 {
      text-align: center;
      margin: 20px;
    }
    p {
      font-weight: 500;
    }
  }

  .main-points {
    display: flex;
    flex-direction: column;

    .point {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin: 10px;
      padding: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      &.last {
        .left {
          width: 100%;
        }
      }

      .left {
        width: 60%;
        padding: 10px;
        padding-right: 30px;

        h2,
        h1 {
          margin-bottom: 20px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.6);
        }

        ul {
          margin: 20px 0;
          li {
            opacity: 0.9;
            text-transform: uppercase;
            margin: 13px 0;
            color: rgb(0, 0, 0);
          }
        }

        p {
          font-weight: 500;
        }
      }

      .img-container {
        position: relative;
        height: 400px;
        width: 400px;
        overflow: hidden;
        border-radius: 5px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
        background: white;

        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          transition: transform 0.5s;

          &.img-center {
            top: -80px;
          }

          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .blog-content {
    .main-points {
      .point {
        flex-direction: column;
        align-items: center;
        margin: 20px 0;
        padding: 0;

        .left {
          width: 100%;
        }

        .img-container {
          width: 100%;
          height: 300px;
          width: 340px;

          img {
            &.img-center {
              top: -60px;
            }
          }
        }
      }
    }
  }
}
