#deals {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 96%;
  margin: 10px auto;
}

#deals td,
#deals th {
  border: 1px solid #ddd;
  padding: 8px;
}

#deals tr:nth-child(even) {
  background-color: #f2f2f2;
}

#deals tr:hover {
  background-color: rgba(236, 235, 235, 0.849);
}

#deals th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #011752d5;
  color: white;
}

#deals td {
  .decision-btn {
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.4);
    padding: 4px 10px;
    margin: 0 10px;

    &:first-child {
      margin-left: 0;
    }
  }
}
