.UserDeals-container {
  padding-left: 71px;

  @media (max-width: 800px) {
    padding-left: 0px;
  }
  .msg {
    text-align: center;
    width: 100%;
    margin-top: 30px;
    font-size: 18px;
    font-weight: 300;
  }

  .user-deals {
    .deals-container {
      .deals {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 3fr));
        grid-gap: 20px;
        width: 100%;
        padding: 20px;

        @media (max-width: 540px) {
          padding: 20px 10px;
        }

        .card {
          width: 100%;
          padding: 25px;
          margin: 20px 0;
          text-decoration: none;

          &.single {
            width: 33%;

            @media (max-width: 600px) {
              width: 100%;
            }
          }

          @media (max-width: 540px) {
            padding: 20px;
            margin: 10px 0;
          }

          &:hover {
            transition: box-shadow 300ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
            box-shadow: 0px 0px 1px 0 rgba(0, 0, 0, 0.6);
          }

          img {
            width: 100%;
            border: 1px solid rgba(63, 61, 61, 0.5);
          }

          .title {
            color: #212121;
            font-size: 1.3em;
            margin: 10px 0px;
          }

          .about {
            color: #424242;
            font-size: 1.1em;
            margin: 10px 0px;
            line-height: 28px;
          }
        }
      }
    }
  }
}
