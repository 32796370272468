@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400&display=swap");

:root {
  --border-rad: 4px;
}

.mainContainer {
  background-color: rgb(200, 233, 249);
  min-height: 100vh;
  padding: 0 10%;
  font-family: "Source Sans Pro", sans-serif;
  color: black;
}

input {
  font-family: "Source Sans Pro", sans-serif;
}

.filterContainer {
  background-color: rgb(255, 255, 255);
  min-height: 100%;
  min-height: 100vh;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  overflow-x: scroll;
  position: relative;
}

.searchHeader {
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.searchHeader input {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.05);
  padding: 8px 10px;
  transition: 0.4s;
  font-size: 15px;
  width: 250px;
}

.searchHeader input:focus {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.1);
}

.no-data {
  position: absolute;
  font-family: "Source Sans Pro", sans-serif;
  text-align: center;
  margin-top: 30px;
  width: 100%;
}

#itemsTable {
  width: 100%;
}

#itemsTable thead {
  background-color: rgba(55, 55, 192, 0.5);
}

#itemsTable thead tr th {
  text-transform: uppercase;
  min-width: 150px;
}

#itemsTable tbody tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  transition: 0.4s;
}

#itemsTable tbody tr:hover {
  background-color: #3f519b1f;
}

#itemsTable tbody tr td:last-of-type {
  border-top-right-radius: var(--border-rad);
  border-bottom-right-radius: var(--border-rad);
}
#itemsTable tbody tr td:first-of-type {
  border-top-left-radius: var(--border-rad);
  border-bottom-left-radius: var(--border-rad);
  border-left: var(--border-rad) solid transparent;
}
#itemsTable th,
#itemsTable td {
  padding: 15px 20px;
  text-align: left;
}

@media (max-width: 600px) {
  .mainContainer {
    padding: 0 10px;
  }

  .searchHeader {
    flex-direction: column;
    height: 110px;
  }

  #itemsTable {
    font-size: 14px;
  }

  #itemsTable thead tr th {
    min-width: 120px;
  }
}
