.card__main {
  width: 50%;
  border-radius: 20px;
}

.card__main:hover {
  box-shadow: 3px 5px 15px 5px white;
}
.card__main_main:hover{
 box-shadow: 3px 5px 15px 5px white;
}

.card__main_main{
  width: 80%;
  border-radius: 20px;

}

.header__main {
  background: #3AB2D0;
}

@media screen and (max-width: 700px) {
  .card__main {
    width: 100%;
  }
}

.center {
  background-color: #3AB2D0;
  padding-top: 15%;
  padding-left: 50%;
  height: 100rem;
}
