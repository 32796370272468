#campaign-container {
    place-items: center;
  }
  .pros-card {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  
}

@media (min-width:500px) and (max-width:760px){
    .imgid1{
        height: 10rem;
    }
}

  #campaign-container #heading {
    color: black;
    font-size: 28px;
    animation: 0.8s top-fade linear forwards;
    transform: translateY(-300px);
    opacity: 0;
    font-weight: 500;
    text-transform: uppercase;
  }
  
  .hero-img {
    opacity: 0;
    animation: 1s top-fade linear forwards;
  }
  
  #campaign-container #heading2,
  #campaign-container #heading3 {
    margin: 10px;
    margin-left: 20px;
    line-height: 32px;
    opacity: 0;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);
    font-weight: lighter;
  }
  
  #campaign-container #heading2 {
    animation: 1s top-fade linear forwards;
    transform: translate(-800px, 0);
  }
  
  #campaign-container #heading3 {
    animation: 1s top-fade linear forwards;
    transform: translate(800px, 0);
  }
  
  .pros-card {
    border: 0;
    box-shadow: none;
    margin: 65px;
    background: transparent;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 white;
    color: white;
    /* border: 2px solid rgba(255, 255, 255, 0.767); */
  }
  
  .pros-card p {
    line-height: 45px;
    font-size: 18px;
    text-transform: uppercase;
  }
  
  .pros-number {
    position: absolute;
    font-size: 90px;
    top: 40px;
    left: -140px;
    color: rgba(235, 53, 107, 0.9);
    opacity: 0.2;
    letter-spacing: 6px;
  }
  
  .campaign-btn {
    border-radius: 2px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
      7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
    outline: none;
    width: 150px;
    height: 45px;
    padding: 0;
    border: none;
    margin-top: 20px;
    text-transform: uppercase;
    font-size: 13px;
    padding: 5px 10px;
    border: 1px solid rgb(0, 172, 238);
    color: black;
    background: rgba(0, 0, 0, 0.05);
  }
  
  .campaign-btn:first-child {
    margin-left: 0;
    margin-right: 0;
  }
  
  .campaign-btn:hover {
    color: #fff;
    background: transparent;
    background: linear-gradient(0deg, rgb(21, 133, 238) 0%, rgb(7, 11, 238) 100%);
  }
  
  .camp-modal {
    max-height: calc(100vh);
    overflow-y: auto;
    background: transparent;
  }
  .camp-modal-body {
    width: 1000px;
    margin: auto;
  }
  
  .back-btn {
    outline: none;
    border: 0;
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 40px;
  }
  
  .obj-card {
    background-color: rgba(250, 19, 88, 0.7);
    box-shadow: 8px 10px 8px rgb(0, 0, 0, 0.4);
    height: 150px;
    border: 1px #949597 solid;
    margin: 40px 25px;
    display: grid;
    backdrop-filter: blur(3px);
    place-items: center;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border-radius: 10px;
  }
  .obj-card:hover {
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1);
  }
  
  .obj-card.selected-obj {
    background: rgba(171, 245, 60, 1);
    transition: all 0.3s ease-in-out;
  }
  
  .obj-card h1 {
    font-size: 19px;
    font-weight: lighter;
    text-align: center;
    line-height: 30px;
    opacity: 1;
  }
  .step-two {
    display: none;
  }
  
  .section-heading {
    font-size: 40px;
    opacity: 0.35;
    -webkit-text-stroke: 2px black;
    -webkit-text-fill-color: transparent;
    margin: 0 !important;
  }
  
  .submit-campaign input {
    width: 95%;
    background: rgba(0, 0, 0, 0.288);
    border-radius: 10px;
    padding: 5px 15px;
    margin: 15px !important;
    outline: none;
    border: 2px solid black;
    font-size: 17px;
    font-weight: lighter;
  }
  
  .submit-campaign input::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
  /* 
  .submit-campaign .dropdown-menu {
    width: 600px !important;
    margin-right: 20px;
  }
  
  .submit-campaign .btn-light {
    width: 600px !important;
  } */
  
  .submit-campaign .select-box {
    width: 60vw;
    margin: 25px 17px;
  }
  
  .submit-campaign div p {
    font-size: 18px;
    text-align: center;
    margin: 10px;
    color: rgba(0, 0, 0, 0.6);
  }
  
  .submit-campaign div textarea {
    width: 100%;
    border-radius: 6px;
    padding: 15px;
    font-size: 17px;
    margin: 10px;
    border: 0;
    resize: none;
    height: 200px;
    border: 1px solid rgba(0, 0, 0, 0.5);
  }
  
  .submit-campaign .reward {
    width: 50%;
    margin: auto;
  }
  
  .submit-campaign .reward-cards {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 24px;
  }
  
  .submit-campaign .reward-card {
    width: 180px;
    height: 50px;
    background: rgba(75, 47, 202, 0.521);
    display: flex;
    cursor: pointer;
    color: white;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin: 10px;
  }
  
  .submit-campaign .reward-card.selected-reward {
    background: blue;
  }
  
  .submit-campaign .reward-card span {
    font-size: 15px;
  }
  
  .submit-campaign .final-btn {
    outline: none;
    border: 2px solid rgba(255, 255, 255, 0.699);
    background: linear-gradient(to right, #f14658, #dc2537);
    color: white;
    width: 180px;
    height: 60px;
    margin: 40px;
    margin-bottom: 50px;
    border-radius: 7px;
  }
  
  .submit-campaign .final-btn:hover {
    background: rgb(9, 54, 252);
  }
  
  #heading-five {
    font-size: 24px;
    margin: 0;
    padding-left: 50px;
  }
  
  #inf-types {
    background-color: red !important;
    margin: 0 !important;
    padding: 0 !important;
    background: transparent !important;
  }
  
  @media all and (max-width: 500px) {
    .modal-content .close {
      font-size: 38px;
    }
  
    .modal-content {
      padding: 12px !important;
    }
  
    #campaign-container #heading {
      font-size: 22px;
    }
  
    #campaign-container #heading2 {
      font-size: 14px;
    }
  
    #campaign-container #heading3 {
      font-size: 13px;
    }
    .pros-card {
      margin: 70px auto;
      height: 500px;
      width: 94%;
    }
  
    .pros-number {
      display: none;
    }
  
    .pros-card p {
      font-size: 15px;
      text-align: center;
      text-transform: capitalize;
    }
  
    .obj-card {
      width: 220px;
      margin-top: 40px;
    }
  
    .obj-card h1 {
      font-size: 14px;
    }
  
    .submit-campaign .reward {
      width: 100%;
      margin: auto;
    }
  
    .submit-campaign .select-box {
      width: 78vw;
      margin: 25px 17px;
    }
    .one {
      order: 2 !important;
    }
    .two {
      margin-top: 25px !important;
    }
  
    nav-new {
      top: 0px;
      z-index: 100;
      position: sticky !important;
    }
  
    #heading-five {
      padding-left: 23px !important;
      padding-top: 140px !important;
      font-size: 22px !important;
    }
  
    ::-webkit-input-placeholder {
      font-size: 13px;
    }
    .camp-modal {
      height: 100% !important;
      margin-left: 2px !important;
    }
  
    .camp-modal-body- {
      width: 100vw !important;
      padding-right: 65px !important;
    }
  }
  
  .close {
    font-size: 35px;
  }
  
  @media all and (max-width: 360px) {
    #inf-types {
      margin: 50px !important;
    }
  
    .pros-card {
      margin: 70px auto;
      height: 500px;
      width: 100%;
    }
    .pros-card p {
      font-size: 13px;
      text-align: center;
    }
  }
  
  @media all and (min-width: 600px) {
    .camp-con {
      height: 680px !important;
    }
    .camp-modal-body {
      margin-left: 30px;
    }
    .hero-img {
      min-width: 650px !important;
      min-height: 650px !important;
    }
  }
  @keyframes top-fade {
    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  
  @media all and (max-width: 360px) {
    .camp-modal {
      transform: translateY(0px);
      min-height: 100%;
    }
  
    .modal-content {
      padding: 12px !important;
    }
  
    .submit-campaign .select-box {
      width: 70vw;
      margin: 25px 17px;
      font-size: 14px;
    }
  
    .camp-modal-body- {
      align-items: flex-start !important;
      box-sizing: border-box;
      padding-right: 50px !important;
    }
  
    .submit-campaign .reward {
      width: 300px;
      margin: 4px;
      font-size: 11px;
    }
  
    .submit-campaign .reward-cards {
      flex-direction: column;
    }
  
    #budget {
      font-size: 15px !important;
    }
  
    #budget::placeholder {
      font-size: 10px !important;
    }
  
    .custom-alert span {
      font-size: 15px;
      padding: 30px 40px;
    }
  }
  
  .custom-alert {
    position: fixed;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transition: 0.2s all ease-in-out;
    display: none;
  }
  
  .custom-alert span {
    font-size: 16px;
    padding: 40px 50px;
    background: linear-gradient(#f14658, #dc2537);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    color: white;
    transition: 0.4s all linear;
    text-align: center;
  }
  
  .custom-alert span.show-msg {
    animation: fade-from-top 0.7s forwards;
    transform: translateY(-250px);
  }
  
  @keyframes fade-from-top {
    100% {
      transform: translateY(20px);
      opacity: 1;
    }
  }
  
  .custom-alert span.hide-msg {
    animation: fade-from-bottom 0.7s forwards;
  }
  
  @keyframes fade-from-bottom {
    100% {
      transform: translateY(-250px);
    }
  }
  