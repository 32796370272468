@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400&display=swap");

.profile {
  .img-div {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

    input {
      margin: 14px;
    }

    button {
      margin: 0 14px;
      margin-bottom: 10px;
      margin-right: 0;
      font-size: 15px;
      border: 1px solid rgba(0, 0, 0, 0.6);
      padding: 3px 21px;
    }

    span {
      margin-left: 5px;
      font-size: 16px;
      color: green;
    }
  }

  .ref-applied-link {
    font-family: "Source Sans Pro", sans-serif;
    margin: 20px 0;
    a {
      text-decoration: none;
      color: blue;
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 5px 10px;
      margin: 10px;
      text-transform: uppercase;
      font-size: 14px;
    }
  }

  .ref-code {
    display: flex;
    align-items: center;
    margin: 10px 0;
    .btn {
      border: 1px solid rgba(0, 0, 0, 0.5);
      outline: 0;
    }

    p {
      margin: 0 25px;
      font-weight: 500;

      button {
        background-color: transparent;
        outline: none;
        border: 0;
      }
    }
  }
}
