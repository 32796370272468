.home{
    font-family: Arial, Helvetica, sans-serif;
    font-weight:bold;
}
.card-section {
  background-color: #3AB2D0;
  height:100%
}

.my-cards{
    background: transparent;
    border: none;
    border-radius: 30px 30px 30px 30px;

}
.my-cards p{
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif

}
.my-cards h3{
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight:bold;
}

.my-img:hover {
    box-shadow: 10px 10px 20px 3px rgb(219, 219, 219);
}

.text {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: bolder;
}

.my-img{
    border-radius: 30px 30px 30px 30px;
    box-shadow: 5px 5px 20px 3px rgb(255, 255, 255);

}