.notifications {
  padding: 0px;
  margin: 0 !important;
  padding-left: 71px;
  min-height: 100vh;
  background-color: white;

  @media (max-width: 768px) {
    padding-left: 0;
  }
  .notifications-container {
    .msg {
      text-align: center;
      width: 100%;
      margin-top: 30px;
      font-size: 18px;
      font-weight: 300;
    }

    .notifications-list {
      .notification-item {
        background: rgb(248, 245, 245);
        margin: 20px;
        padding: 15px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        p {
          margin: 4px 0;

          span {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
