// $primary: #011752d5;
$primary: #3ab2d0;
$secondary: #c1c4c9;
$background: #f9f9fa;
$hoverBackground: rgba(255, 255, 255, 0.1);
$hamburgerColor: #fff;
$navWidth: 70px;
$navExpandedWidth: 300px;
$navMobileWidth: 80%;
$navMobileHeight: 50px;
$transTime: 300ms;
$transTimeFast: 100ms;
$itemHeight: 50px;
$iconBar1: 30px;
$iconBar2: 24px;
$iconBar3: 18px;
$hamburgerWidth: 30px;
$hamburgerPosition: ($navWidth - $hamburgerWidth) / 2;
$hamburgerHeight: 22px;
$hamburgerMobilePosition: ($navMobileHeight - $hamburgerHeight) / 2;

.container-div{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 42px;
}

.admin-container {
  width: 100%;
  padding-left: 71px !important;

  @media (max-width: 800px) {
    padding: 0 !important;
  }
  
}
nav {
  z-index: 99;
  height: 100%;
  width: $navWidth;
  background-color: $primary;
  backdrop-filter: blur(2px);
  position: fixed;
  left: 0;
  top: 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px 0px;
  display: flex;
  flex-direction: column;
  -webkit-transition: width $transTime cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    height $transTime cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  transition: width $transTime cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    height $transTime cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  overflow: hidden;
}

.expanded {
  width: $navExpandedWidth;
}

.hamburger-menu {
  position: fixed;
  left: $hamburgerPosition;
  top: $hamburgerPosition;
  cursor: pointer;
  width: $hamburgerWidth;
  z-index: 100;
  transition: transform;
  transition-duration: $transTime;
  transition-timing-function: cubic-bezier(0.7, 0, 0, 0.7);
}

.icon-bar {
  background-color: $hamburgerColor;
  display: block;
  position: relative;
  float: left;
  height: 4px;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 5px;
}

.icon-bar:nth-child(1) {
  transition: transform;
  transition-duration: $transTime;
  transition-timing-function: ease-in-out;
}

.icon-bar:nth-child(2) {
  width: $iconBar2;
  transition: transform;
  transition-duration: $transTime;
  transition-timing-function: ease-in-out;
}

.icon-bar:nth-child(3) {
  width: $iconBar3;
  transition: transform, width;
  transition-duration: $transTime;
  transition-timing-function: ease-in-out;
}

.hamburger-menu:not(.collapsed) .icon-bar:nth-child(1) {
  transform: translateY(9px) rotate(-45deg);
}

.hamburger-menu:not(.collapsed) .icon-bar:nth-child(2) {
  transform: scale(0);
}

.hamburger-menu:not(.collapsed) .icon-bar:nth-child(3) {
  transform: translateY(-9px) rotate(45deg);
  width: 30px;
}

.wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.menu {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.item {
  color: $secondary;
  height: $itemHeight;
  width: $navExpandedWidth;
  display: flex;
  flex-wrap: nowrap;
  font-size: 16px;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.item:hover {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.842) 10%, transparent);
  cursor: pointer;
  color: #fff;
}

.item > a {
  height: 100%;
  flex-grow: 1;
  text-decoration: none;
  // padding-left: 70px;
  text-transform: capitalize;
  line-height: 50px;
  font-size: 17px;
  display: flex;
  align-items: center;
}
.item > a .MuiSvgIcon-root {
  margin: 0 20px;
  margin-right: 15px;
}

.item > a span {
  display: none;
}

.expanded {
  .item {
    a {
      padding-left: 40px;
      span {
        display: block;
        margin-left: 10px;
      }
      .MuiSvgIcon-root {
        margin: 0;
      }
    }
  }
}

.item > a:hover {
  color: white;
}

@media screen and (max-width: 768px) {
  .admin-container {
    padding-left: 0;
    margin-top: 70px;
  }

  nav {
    height: $navMobileHeight;
    width: 100%;
  }

  .hamburger-menu {
    top: $hamburgerMobilePosition;
    left: $hamburgerMobilePosition;
  }

  .expanded {
    height: 100%;
  }
}
