.coming-deals {
  .options {
    padding: 10px 20px;
    box-shadow: 0px 0px 1px 0 rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;

    button {
      outline: none;
      border: 0;
      cursor: pointer;
      background: none;
      box-shadow: 0px 0px 1px 0 rgba(0, 0, 0, 1);
      margin: 10px;
      padding: 10px 20px;

      &:hover {
        background: rgba(0, 0, 0, 0.8);
        color: white;
      }
    }
  }

  .output {
    min-height: 80vh;
    padding: 20px;
  }

  .loader {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 100;
    display: none;
    place-items: center;
    p {
      color: white;
      width: 50%;
    }
  }

  #deals {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #deals td,
  #deals th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  #deals tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #deals tr:hover {
    background-color: rgba(236, 235, 235, 0.849);
  }

  #deals th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #011752d5;
    color: white;
  }

  #deals td {
    .interested-users-link {
      text-decoration: none;
      color: blue;
    }
    .decision-btn {
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.4);
      padding: 4px 10px;
      margin: 0 10px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
