.header-row {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  margin: 20px 0px;

  input {
    padding: 10px 15px;
    width: 350px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: none;

    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.5);
    }
  }

  .filters {
    width: 300px;
    label {
      font-size: 20px;
      margin: 0px 10px;
    }

    select {
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 10px 8px;

      option {
        font-weight: 400;
      }
    }
  }

  .total-users {
    font-size: 17px;
    color: blue;
    text-transform: uppercase;
  }
}
