.user-info-header {
  text-shadow: 2px 1px 2px;
  color: #011752d5;
}

.requestedDeals {
  display: flex;
  padding: 32px;
  width: 100%;
  flex-direction: column;
  .info-container {
    h1 {
      text-shadow: 2px 1px 2px;
      color: #011752d5;
    }
    width: 100%;
    h6 {
      color: #011752d5;
      letter-spacing: 1.2px;
    }
    h4 {
      letter-spacing: 1.1px;
    }
  }
  a {
    color: white !important;
    text-decoration: none !important;
  }

  .types {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    font-size: 24px;
    .link-div {
      width: 50%;
      text-align: center;
      margin-bottom: 12px;
    }
    div {
      // background-color: #011752d5;
      background-color: #3ab2d0;
      color: white;
      border-radius: 4px;
      padding: 6px;
      transition: 0.6s;
    }
    div:hover {
      background-color: #011752d5;
    }
  }
}

.specificTypeDeals {
  width: 90%;
  max-width: 2100px;
  margin: 10px auto;
  padding: 32px;

  @media (max-width: 600px) {
    width: 96%;
  }

  .msg {
    text-align: center;
    width: 100%;
    margin-top: 30px;
    font-size: 18px;
    font-weight: 300;
  }
  #deals {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #deals td,
  #deals th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  #deals tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #deals tr:hover {
    background-color: rgba(236, 235, 235, 0.849);
  }

  #deals th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #011752d5;
    color: white;
  }

  #deals td {
    .decision-btn {
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.4);
      padding: 4px 10px;
      margin: 0 10px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
