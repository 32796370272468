.create-task {
  .create-task-form-container {
    #create-task-form {
      width: 50%;
    }
  }
}

@media screen and (max-width: 660px) {
  .create-task {
    .create-task-form-container {
      #create-task-form {
        width: 90%;
      }
    }
  }
}
