.loading-container {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-container .loading {
  width: 80px;
  display: flex;
  flex-wrap: wrap;
  animation: rotate 3s linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.loading span {
  width: 32px;
  height: 32px;
  margin: 4px;
  background-color: red;
  animation: scale 1.5s linear infinite;
}

@keyframes scale {
  50% {
    transform: scale(1.2);
  }
}

.loading span:nth-child(1) {
  border-radius: 50% 50% 0 50%;
  background-color: #e77f67;
  transform-origin: bottom right;
}

.loading span:nth-child(2) {
  border-radius: 50% 50% 50% 0%;
  background-color: #778beb;
  transform-origin: bottom left;
  animation-delay: 0.5s;
}

.loading span:nth-child(3) {
  border-radius: 50% 0% 50% 50%;
  background-color: #f8a5c2;
  transform-origin: top right;
  animation-delay: 1.5s;
}

.loading span:nth-child(4) {
  border-radius: 0% 50% 50% 50%;
  background-color: #f5cd79;
  transform-origin: top left;
  animation-delay: 1s;
}
