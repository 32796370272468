.banner-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 2fr));
  place-items: center;
  padding: 20px;

  @media (max-width: 770px) {
    grid-template-columns: 100%;
  }

  .banner-container {
    width: 100%;
    display: grid;
    place-items: center;
    margin: 20px 10px;
    font-family: "IBM Plex Sans", sans-serif;

    @media (max-width: 770px) {
      &:first-child {
        margin-top: 30px;
      }

      padding-left: 0;
    }

    .banner {
      width: 80%;
      min-height: 100%;
      box-shadow: 0px 0px 1px 0 rgba(0, 0, 0, 1);
      background: #ebf9fc;

      @media (max-width: 770px) {
        width: 96%;
      }

      header {
        padding: 10px 20px;
        border-bottom: 1px solid rgba(63, 61, 61, 0.2);
      }

      footer {
        padding: 10px;
        border-top: 1px solid rgba(63, 61, 61, 0.2);
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100px;

        button {
          margin: 10px !important;
          color: white;
          background: #3ab2d0;
          width: 100%;

          &:disabled {
            background: green;
            cursor: not-allowed;
          }
        }
      }

      section {
        min-height: 280px;
        padding: 10px 30px;
        padding-bottom: 3px;

        .image {
          height: 230px;

          img {
            height: 100%;
            width: auto;
            box-shadow: 0px 0px 20px 0 rgba(0, 0, 0, 0.3);
          }
        }

        p {
          font-weight: 400;
          font-size: 18px;
          margin-top: 8px;
        }
      }
    }
  }
}
